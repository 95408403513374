<template>
  <div>
    <vx-card title="Mitarbeiter hinzufügen">
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-1">
          <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-select
                class="selectExample w-full"
                label="Anrede"
                v-model="salutation"
              >
                <vs-select-item :is-selected.sync="item.isSelected" :key="index" :value="item.value" :text="item.label"
                                v-for="(item,index) in salutations"/>
              </vs-select>
            </div>
          </div>
          <div class="vx-row mb-1">
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-input class="w-full" v-validate="'required|min:3'" label-placeholder="First Name" name="firstname" v-model="firstname"/>
              <span class="text-danger text-sm">{{ errors.first('firstname') }}</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-input class="w-full" v-validate="'required|min:3'" label-placeholder="Last Name" name="lastname"  v-model="lastname"/>
              <span class="text-danger text-sm">{{ errors.first('lastname') }}</span>
            </div>
          </div>
          <div class="vx-row mb-1">
            <div class="vx-col w-full">
              <vs-input class="w-full" v-validate="'required|email'" type="email" label-placeholder="Email" name="email" v-model="email"/>
              <span class="text-danger text-sm">{{ errors.first('email') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6 mt-6">
            <div class="vx-col w-full">
              <vx-input-group class="mb-0">
                <vs-input v-model="password"  v-validate="'required|min:10'" placeholder="Passwort" name="password" />
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button color="primary" @click="generatePassword">Generieren</vs-button>
                  </div>
                </template>
              </vx-input-group>
              <span class="text-danger text-sm">{{ errors.first('password') }}</span>
            </div>
          </div>
        </div>


        <div class="vx-col sm:w-1/2 w-full mb-1">
          <div class="vx-row mb-2">
            <div class="vx-col w-full mb-0">
              <vs-select
                class="selectExample w-full"
                label="Rolle"
                v-model="role"
              >
                <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in roles"/>
              </vs-select>
            </div>
          </div>
        </div>
      </div>


      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" :disabled="!validateForm" @click="createUser">Hinzufügen</vs-button>
          <vs-button color="warning" type="border" class="mb-2" to="/employees">Abbrechen</vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import ApiService from "../../../api/index";
  import staticOptions from "../../../mixins/static/options";

  export default {
    name: "AddEmployees",
    data() {
      return {
        selected: [],
        log: [],
        roles: [],
        salutation: 1,
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        role: 1,
        salutations: staticOptions.salutations
      }
    },
    created() {
      ApiService.get('roles').then(response => {
        if (response.status !== 200) {
          return;
        }

        this.roles = response.data.result;
        this.role = response.data.result[0].id

      }).catch(response => {

      })
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email !== '' && this.password !== ''  && this.firstname !== '' && this.lastname !== '';
      },
    },
    methods: {
      generatePassword() {
        var length = 10,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return this.password = retVal;
      },
      createUser() {
        let me = this;

        if (!this.validateForm) return

        me.$vs.loading()

        const payload = {
          email: this.email,
          password: this.password,
          last_name: this.lastname,
          first_name: this.firstname,
          role_id: this.role,
          salutation_id: this.salutation
        }

        ApiService.post('users', payload).then((response) => {

          me.$vs.loading.close()

          me.$vs.notify({
            title: 'Erfolgreich',
            text: 'Ihr Account wurde erfolgreich angelegt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })

          me.$router.push('/employees');

        }).catch((response) => {

          me.$vs.loading.close()

          me.$vs.notify({
            title: 'Error',
            text: response.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })

        });

      }

    }
  }
</script>

<style scoped>

</style>
